<template>
  <div class="row m-0 align-items-center borders py-md-3 py-4" >
    <div class="col-md-auto ps-4 pe-0">
      <ios-close-circle-icon class="inline-icon delete" @click="deleteItem"/>
    </div>
    <!--IMAGE-->
    <div class="col-12 col-md-2 px-4 text-center" v-if="projectDetails">
      <Image :imageName="projectDetails.imageUrl" width="40" theClass="item_img" v-if="projectDetails.imageUrl"/>
      <img src="@/assets/images/default.png"  class="item_img" v-else>
    </div>
    <!--END IMAGE-->
    <!--DETAILS-->
    <div class="col-12 col-md p-0 text-center text-md-start mt-2  mt-md-0">
      <div class="row m-0">
        <div class="col-md-12 p-0 green-text bold item">
          <div class="row mx-0" v-if="selectedProject">
            <div class="col-12 p-0 mb-3" v-if="parentProjects && parentProjects.length > 0">
              <SelectAll
                v-model="selectedParentProject"
                id="project"
                :options="parentProjects"
                placeholder="Select Project"
                displayValue="description"
                color="white"
                :isSearch="true"
              />
            </div>
            <div class="col-12 p-0 mb-3" v-if="selectedParentProject && Object.keys(selectedParentProject).length !== 0 && childProjects.length > 0">
              <SelectAll
                v-model="selectedChildProject"
                id="project"
                :options="childProjects"
                placeholder="Select Child Project"
                displayValue="description"
                color="white"
                :isSearch="true"
              />
            </div>
          </div>
        </div>
        <div class="col-md-12 p-0 font12">
          <div class="row mt-2 justify-content-center justify-content-md-start">
            <div class="col-11 col-md-auto mb-1" v-for="(type, index) in editItem.donationTypes" :key="index">
              <CurrencyField :label="type.type.donationType.description" v-model="type.amount" size="small" color="outline-green" @input="setTotal" />
            </div>
          </div>
        </div>
        <div class="col-md-12 p-0 font12" v-if="modelValue.plaqueDescription">
          <div class="row mt-2 justify-content-center justify-content-md-start">
            <div class="col-11 col-md-auto mb-1">
              <TextArea placeholder='Enter your personalized plaque message here e.g. "Sponsored by [Donor Name] for the Esaal-e-Sawaab of [Name}"' v-model="editItem.plaqueDescription" id="plaqueDescription" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--END DETAILS-->
    <div class="col col-md-3 col-lg-2 pe-4 item text-center md-text-end bold mt-3 mt-md-0 text-nowrap" >
      {{ editItem.currency }}{{displayEditTotal}}
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { defineAsyncComponent } from 'vue'

export default {
  components: {
    Image: defineAsyncComponent(() => import('@/components/Image.vue')),
    CurrencyField: defineAsyncComponent(() => import('@/components/CurrencyField.vue')),
    SelectAll: defineAsyncComponent(() => import('@/components/SelectAll.vue')),
    TextArea: defineAsyncComponent(() => import('@/components/TextArea.vue')),
    'ios-close-circle-icon': defineAsyncComponent(() => import('vue-ionicons/dist/ios-close-circle.vue'))
  },
  name: 'CartItem',
  props: ['modelValue'],
  emits: ['update:modelValue', 'deleteItem'],
  data () {
    return {
      url: process.env.VUE_APP_URL_BASE,
      selectedProject: {},
      selectedParentProject: {},
      selectedChildProject: {},
      editItem: {}
    }
  },
  async mounted () {
    this.editItem = { ...this.modelValue }
    if (this.projectDetails) {
      await this.matchProject()
    }
  },
  watch: {
    editItem: {
      deep: true,
      async handler (val) {
        if (this.editItem !== this.modelValue) {
          await this.$emit('update:modelValue', val)
        }
      }
    },
    async selectedProject () {
      if (this.selectedProject && Object.keys(this.selectedProject).length > 0) {
        await this.setTypeAmounts()
        this.editItem.projectId = this.selectedProject.projectID
      }
    },
    async selectedParentProject () {
      if (this.selectedParentProject && Object.keys(this.selectedParentProject).length > 0) {
        if (this.selectedChildProject && Object.keys(this.selectedChildProject).length > 0) {
          this.selectedChildProject = {}
          this.selectedProject = this.selectedParentProject
        } else {
          this.selectedProject = this.selectedParentProject
        }
      }
    },
    async selectedChildProject () {
      if (this.selectedChildProject && Object.keys(this.selectedChildProject).length > 0) {
        this.selectedProject = this.selectedChildProject
      }
    }
  },
  computed: {
    ...mapGetters([
      'adminProjects',
      'contentProjects'
    ]),
    updateValue: {
      get () {
        return this.modelValue
      },
      set (val) {
        this.$emit('update:modelValue', val)
      }
    },
    parentProjects () {
      return this.adminProjects.filter(project => {
        if (project.parentProjectID !== 0) {
          return false
        }
        return true
      })
    },
    childProjects () {
      const arr = this.adminProjects.filter(project => {
        if (this.selectedParentProject && project.parentProjectID !== this.selectedParentProject.projectID) {
          return false
        }
        return true
      })
      arr.sort((a, b) => (a.description > b.description ? 1 : -1))
      return arr
    },
    projectDetails () {
      const adminProject = this.adminProjects.find(project => project.projectID === this.modelValue.projectId)
      const contentProject = this.contentProjects.find(project => project.adminProjectId === this.modelValue.projectId)
      if (contentProject) {
        const mergedProject = { ...contentProject, ...adminProject }
        return mergedProject
      } else {
        return adminProject
      }
    },
    displayEditTotal () {
      return parseFloat(Math.round(this.editItem.amount * 100) / 100).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
    }
  },
  methods: {
    ...mapActions(['editCart', 'deleteCartItem']),
    async matchProject () {
      if (this.projectDetails) {
        if (this.projectDetails.parentProjectID === 0 || this.projectDetails.parentProjectID === '0') {
          this.selectedParentProject = this.projectDetails
        } else if (this.projectDetails.parentProjectID && this.projectDetails.parentProjectID !== 0) {
          await this.getParentProject(this.projectDetails.parentProjectID)
          this.selectedChildProject = this.projectDetails
        }
      }
    },
    getParentProject (id) {
      const theProject = this.adminProjects.filter(project => {
        if (project.projectID !== id) {
          return false
        }
        return true
      })
      this.selectedParentProject = theProject[0]
    },
    setTotal () {
      let theTotal = 0
      this.editItem.donationTypes.forEach((dType) => {
        if (dType.amount > 0) {
          theTotal += parseFloat(dType.amount)
        }
      })
      this.editItem.amount = theTotal
    },
    setTypeAmounts () {
      const donationTypes = []
      this.selectedProject.projectDonationTypeMaps.forEach((dType) => {
        const obj = { type: dType, amount: 0 }
        donationTypes.push(obj)
      })
      this.editItem.donationTypes.forEach((dType) => {
        if (dType.amount > 0) {
          const pos = donationTypes.map(e => e.type.donationType.donationTypeID).indexOf(dType.type.donationType.donationTypeID)
          if (pos > -1) {
            donationTypes[pos].amount += dType.amount
          } else {
            donationTypes[0].amount += dType.amount
          }
        }
      })
      this.editItem.donationTypes = donationTypes
    },
    deleteItem () {
      this.$emit('deleteItem')
    }
  }
}
</script>

<style scoped>
.item_img {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  object-fit: cover;
  background-color: rgba(0, 0, 0, 0.1);
}
.item {
  color: #4E5356;
  font-size: 15px;
}
.delete {
  color: #E0E0E0;
  cursor: pointer;
}
.delete:hover {
  color: var(--red-color);
}
.borders {
  border-bottom: 1px solid #E0E0E0;
}
.borders:hover {
  background-color: rgb(244, 244, 244);
}
.hide {
  visibility: hidden;
}
</style>
